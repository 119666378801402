import React, {ReactElement, useEffect, useState} from 'react';
import './Interests.css';
import {useSearchParams} from 'react-router-dom';
import anime from '../assets/images/anime.png';
import flirt from '../assets/images/flirt.png';
import games from '../assets/images/games.png';
import loneliness from '../assets/images/loneliness.png';
import meme from '../assets/images/meme.png';
import movies from '../assets/images/movies.png';
import music from '../assets/images/music.png';
import pets from '../assets/images/pets.png';
import roleplay from '../assets/images/roleplay.png';
import sport from '../assets/images/sport.png';
import travel from '../assets/images/travel.png';
import books from '../assets/images/books.png';

export const Interests = (): ReactElement => {
    const isDark = window.Telegram.WebApp.colorScheme === 'dark';
    const [searchParams, setSearchParams] = useSearchParams();
    const [interests, setInterests] = useState<string[]>(JSON.parse(searchParams.get('active_interests') ?? '[]'));
    const allInterests: Record<string, string> = JSON.parse(searchParams.get('all_interests') ?? '{}');

    useEffect(() => {
        document.body.className = `Interests-Body ${isDark && 'dark'}`;
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
    }, []);

    return (
        <div className={`Interests-container ${isDark && 'dark'}`}>
            <h3 className={'Interests-Title'}>Интересы</h3>
            <p className={'Interests-Description'}>
                Мы попытаемся соединить вас с собеседниками, которые выбрали похожие интересы.
                <br/>
                <br/>
                Выберите ваши интересы:
            </p>
            <div className={`Interests-Grid ${isDark && 'dark'}`}>
                {Object.entries(allInterests)
                    .filter(([key, _]) => key !== 'clear_interests')
                    .map(([key, value], idx) => {
                        const isEnabled = interests.includes(key);
                        return <div
                            key={key}
                            className={`Interests-Grid-Item ${isEnabled && 'enabled'} ${isDark && 'dark'}`}
                            onClick={() => {
                                if (isEnabled) {
                                    setInterests(interests.filter((i) => i !== key));
                                } else {
                                    setInterests([...interests, key]);
                                }
                            }}
                        >
                            <img
                                src={emoji[key]}
                                alt={key}
                                height={32}
                                width={32}
                            />
                            <p className={'Interests-Grid-Item-title'}>{value}</p>
                        </div>
                    })}
            </div>
            <div
                className={`Interests-Clear-Interests-Button ${isDark && 'dark'}`}
                onClick={() => {
                    setInterests([]);
                }}
            >
                <p className={`Interests-Clear-Interests-Button-Text ${isDark && 'dark'}`}>
                    Сбросить интересы
                </p>
            </div>
            <div
                className={`Interests-Save-Button ${isDark && 'dark'}`}
                onClick={() => {
                    window.Telegram.WebApp.sendData(JSON.stringify({interests, event: 'interests'}));
                }}
            >
                <p className={'Interests-Save-Button-Text'}>
                    СОХРАНИТЬ
                </p>
            </div>
        </div>
    );
}

const emoji: Record<string, string> = {
    books,
    anime,
    flirt,
    games,
    loneliness,
    meme,
    movies,
    music,
    pets,
    roleplay,
    sport,
    travel
}
